<template>
	<div class="container">
		<h1 class="sectionHeader">Program daily activity</h1>
		<ion-icon
			:icon="helpCircleOutline"
			slot="end"
			class="icon"
			@click="help"
		></ion-icon>
		<ion-list class="ion-no-padding">
			<ion-item
				v-for="day in weekdays"
				:key="day"
				class="ion-no-padding listItem"
				@click="openActionSheet(day)"
			>
				<ion-list>
					<ion-label class="label">{{ day }}</ion-label>

					<ion-label class="dayData">{{ activityData[day].value }}</ion-label>
				</ion-list>
			</ion-item>
		</ion-list>
		<ion-button class="inputBox signInButton" @click="onButtonClick"
			>Confirm</ion-button
		>
	</div>
</template>

<script>
	import {
		actionSheetController,
		IonList,
		IonItem,
		IonLabel,
		IonButton,
		IonIcon,
	} from "@ionic/vue";
	import { helpCircleOutline } from "ionicons/icons";
	import { set } from "@/services/storage.js";

	import { createToast } from "@/services/toast.js";

	export default {
		name: "program-days",
		components: { IonList, IonItem, IonLabel, IonButton, IonIcon },
		setup() {
			return {
				helpCircleOutline,
			};
		},
		data() {
			return {
				weekdays: [
					"Monday",
					"Tuesday",
					"Wednesday",
					"Thursday",
					"Friday",
					"Saturday",
					"Sunday",
				],
				activityData: {
					Monday: { value: "Easy Run", index: 0 },
					Tuesday: { value: "Long Run", index: 1 },
					Wednesday: { value: "Easy Run", index: 2 },
					Thursday: { value: "Workout", index: 3 },
					Friday: { value: "Rest Day", index: 4 },
					Saturday: { value: "Easy Run", index: 5 },
					Sunday: { value: "Workout", index: 6 },
				},
				//TODO GET VALUE FROM PROGRAM
				programSessions: 2,
			};
		},
		methods: {
			async onButtonClick() {
				const counters = {
					easy: 0,
					long: 0,
					workout: 0,
					rest: 0,
				};

				const typePositions = {
					easy: [],
					long: [],
					workout: [],
					rest: [],
				};

				for (const property in this.activityData) {
					let element = this.activityData[property].value;
					let index = this.activityData[property].index;
					if (element == "Easy Run") {
						counters.easy += 1;
						typePositions.easy.push(index);
					} else if (element == "Long Run") {
						counters.long += 1;
						typePositions.long.push(index);
					} else if (element == "Workout") {
						counters.workout += 1;
						typePositions.workout.push(index);
					} else if (element == "Rest Day") {
						counters.rest += 1;
						typePositions.rest.push(index);
					}
				}
				let consecutiveWorkouts = false;
				typePositions.workout.forEach((element, index) => {
					let nextIndex = index + 1;
					if (nextIndex >= typePositions.workout.length) {
						nextIndex = 0;
					}
					if (
						element + 1 == typePositions.workout[nextIndex] ||
						element - 1 == typePositions.workout[nextIndex] ||
						element - typePositions.workout[nextIndex] == 6
					) {
						consecutiveWorkouts = true;
					}
				});
				let consecutiveRests = false;
				typePositions.rest.forEach((element, index) => {
					let nextIndex = index + 1;
					if (nextIndex >= typePositions.rest.length) {
						nextIndex = 0;
					}
					if (
						element + 1 == typePositions.rest[nextIndex] ||
						element - 1 == typePositions.rest[nextIndex] ||
						element - typePositions.rest[nextIndex] == 6
					) {
						consecutiveRests = true;
					}
				});

				if (counters.easy == 0) {
					await createToast("Make sure you schedule some easy runs.");
				} else if (counters.long == 0) {
					await createToast("Make sure you schedule a long run.");
				} else if (counters.workout != this.programSessions) {
					await createToast(
						`Make sure you schedule the right number of workouts. Your program requires you to run ${this.programSessions} a week.`
					);
				} else if (consecutiveWorkouts) {
					await createToast(
						"Make sure that you do not have workouts on consecutive days."
					);
				} else if (consecutiveRests) {
					await createToast(
						"Make sure that you do not have rests on consecutive days."
					);
				} else {
					console.debug("Passes all tests");
					const {
						Monday,
						Tuesday,
						Wednesday,
						Thursday,
						Friday,
						Saturday,
						Sunday,
					} = this.activityData;

					let data = {
						1: Monday.value,
						2: Tuesday.value,
						3: Wednesday.value,
						4: Thursday.value,
						5: Friday.value,
						6: Saturday.value,
						7: Sunday.value,
					};

					for (const day in data) {
						if (data[day] == "Easy Run") {
							data[day] = "RUN";
						} else if (data[day] == "Long Run") {
							data[day] = "LONG";
						} else if (data[day] == "Workout") {
							data[day] = "SESS";
						} else if (data[day] == "Rest Day") {
							data[day] = "REST";
						}
					}

					console.debug(data);
					await set("programDayData", data);
					this.$emit("done");
				}
			},

			async help() {
				await createToast(
					"First schedule in your session(s), then your long runs. You should then aim to fill the rest of your week with easy runs, leaving a couple days rest. "
				);
			},

			async openActionSheet(day) {
				const actionSheet = await actionSheetController.create({
					buttons: [
						{
							text: "Easy Run",
							handler: () => {
								this.activityData[day].value = "Easy Run";
							},
						},
						{
							text: "Long Run",
							handler: () => {
								this.activityData[day].value = "Long Run";
							},
						},
						{
							text: "Workout",
							handler: () => {
								this.activityData[day].value = "Workout";
							},
						},
						{
							text: "Rest Day",
							handler: () => {
								this.activityData[day].value = "Rest Day";
							},
						},
					],
				});

				await actionSheet.present();
			},
		},
	};
</script>

<style scoped>
	.sectionHeader {
		color: white;
		font-size: 30px;
		font-weight: bold;
		top: 0px;
	}
	.container {
		position: absolute;
		left: 5%;
		top: 10px;
		width: 90%;
		height: calc(100% - 20px);
	}
	.label {
		color: white;
		font-size: 24px;
		font-weight: bold;
		position: relative;
		width: 100%;
		/*border: 2px solid green;*/
	}
	.dayData {
		color: var(--ion-color-medium-tint);
		font-size: 18px;
	}
	.listItem {
		/*border: 2px solid red;*/
		height: fit-content;
	}
	.dayText {
		position: absolute;
		left: 0px;
		width: 100%;
	}
	.activityText {
		position: absolute;
		left: 0px;
		top: 40px;
	}
	.inputBox {
		color: var(--ion-color-light-contrast);
		background: var(--ion-color-light-tint);
		/*border: 2px solid white;*/

		height: 50px;
		box-shadow: 2px;
		--padding-start: 10px;
	}
	.signInButton {
		position: relative;
		top: 20px;
		color: black;
		font-weight: bold;
		width: 100%;
		left: 0%;
	}
	.action-sheet-class {
		--color: white;
	}
	.icon {
		color: white;
		font-size: 30px;
		position: absolute;
		top: 22.5px;
		right: 0px;
	}
</style>

<template>
	<base-layout>
		<template v-slot:toolbar>
			<ion-buttons slot="end">
				<ion-back-button
					color="light"
					default-href="/signin/signup/details2"
					@click="backStage"
				/>
			</ion-buttons>
		</template>
		<program-days @done="finishedStage" />
	</base-layout>
</template>

<script>
	import ProgramDays from "../Userdetails/ProgramDays.vue";
	import { IonButtons, IonBackButton } from "@ionic/vue";
	import { signupWriteProfile } from "@/services/api.js";

	export default {
		components: { ProgramDays, IonButtons, IonBackButton },
		methods: {
			async finishedStage() {
				await signupWriteProfile(true);
				this.$router.push("/signin/signup/details5");
			},
			backStage() {
				this.$router.push("/signin/signup/details2");
			},
		},
	};
</script>

<style scoped></style>
